@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@200;300;400;500;600&display=swap");
html,
body {
  margin: 0;
  height: 100%;
  scroll-behavior: smooth;
  /* overflow: hidden;
  scroll-behavior: smooth;
  -ms-overflow-style: none; */
}
::-webkit-scrollbar {
  display: none;
}
